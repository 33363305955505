import { default as indexrDZk0Ud42wMeta } from "/usr/src/app/pages/account-not-found/index.vue?macro=true";
import { default as account_45not_45foundiOZIkPZsBuMeta } from "/usr/src/app/pages/account-not-found.vue?macro=true";
import { default as applicationszpB6x2VkNZMeta } from "/usr/src/app/pages/applications.vue?macro=true";
import { default as auth_45erroravC00AO3OcMeta } from "/usr/src/app/pages/auth-error.vue?macro=true";
import { default as dashboardhKii8fXWAxMeta } from "/usr/src/app/pages/dashboard.vue?macro=true";
import { default as email_45not_45verifiedSQ2jAgjghLMeta } from "/usr/src/app/pages/email-not-verified.vue?macro=true";
import { default as email_45verificationz3JbXpSMV8Meta } from "/usr/src/app/pages/email-verification.vue?macro=true";
import { default as faqDkOohHQxeIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as forgot_45passwordP0Nxfp4jaIMeta } from "/usr/src/app/pages/forgot-password.vue?macro=true";
import { default as form_45validations3txF7MhGVMeta } from "/usr/src/app/pages/form-validation.vue?macro=true";
import { default as helpQYzekt5ZcHMeta } from "/usr/src/app/pages/help.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as password_45reset_45confirmed7ytzUXhCefMeta } from "/usr/src/app/pages/password-reset-confirmed.vue?macro=true";
import { default as policiessuN3sAamQPMeta } from "/usr/src/app/pages/policies.vue?macro=true";
import { default as referraluVAdb1aokEMeta } from "/usr/src/app/pages/referral.vue?macro=true";
import { default as request_45password_45resetkac3zUDNL5Meta } from "/usr/src/app/pages/request-password-reset.vue?macro=true";
import { default as reset_45email_45sentycn9FTcbyyMeta } from "/usr/src/app/pages/reset-email-sent.vue?macro=true";
import { default as indexlGdpfxGbhNMeta } from "/usr/src/app/pages/resources/index.vue?macro=true";
import { default as session_45timeout0guR2cT4qPMeta } from "/usr/src/app/pages/session-timeout.vue?macro=true";
import { default as sign_45in7sEcJLcQCLMeta } from "/usr/src/app/pages/sign-in.vue?macro=true";
import { default as sign_45upmDla61cpoeMeta } from "/usr/src/app/pages/sign-up.vue?macro=true";
import { default as tailwindVIAYjmNvpdMeta } from "/usr/src/app/pages/tailwind.vue?macro=true";
import { default as doctorsB27NwcXdoXMeta } from "/usr/src/app/pages/user/doctors.vue?macro=true";
import { default as index9wmPuxyvMJMeta } from "/usr/src/app/pages/user/index.vue?macro=true";
import { default as manage_45consentuI9X6YPhtnMeta } from "/usr/src/app/pages/user/manage-consent.vue?macro=true";
import { default as medications9HLT9bl1ZFMeta } from "/usr/src/app/pages/user/medications.vue?macro=true";
import { default as pharmaciescvoyrwsH67Meta } from "/usr/src/app/pages/user/pharmacies.vue?macro=true";
export default [
  {
    name: account_45not_45foundiOZIkPZsBuMeta?.name ?? undefined,
    path: account_45not_45foundiOZIkPZsBuMeta?.path ?? "/account-not-found",
    meta: account_45not_45foundiOZIkPZsBuMeta || {},
    alias: account_45not_45foundiOZIkPZsBuMeta?.alias || [],
    redirect: account_45not_45foundiOZIkPZsBuMeta?.redirect,
    component: () => import("/usr/src/app/pages/account-not-found.vue").then(m => m.default || m),
    children: [
  {
    name: indexrDZk0Ud42wMeta?.name ?? "account-not-found",
    path: indexrDZk0Ud42wMeta?.path ?? "",
    meta: indexrDZk0Ud42wMeta || {},
    alias: indexrDZk0Ud42wMeta?.alias || [],
    redirect: indexrDZk0Ud42wMeta?.redirect,
    component: () => import("/usr/src/app/pages/account-not-found/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: applicationszpB6x2VkNZMeta?.name ?? "applications",
    path: applicationszpB6x2VkNZMeta?.path ?? "/applications",
    meta: applicationszpB6x2VkNZMeta || {},
    alias: applicationszpB6x2VkNZMeta?.alias || [],
    redirect: applicationszpB6x2VkNZMeta?.redirect,
    component: () => import("/usr/src/app/pages/applications.vue").then(m => m.default || m)
  },
  {
    name: auth_45erroravC00AO3OcMeta?.name ?? "auth-error",
    path: auth_45erroravC00AO3OcMeta?.path ?? "/auth-error",
    meta: auth_45erroravC00AO3OcMeta || {},
    alias: auth_45erroravC00AO3OcMeta?.alias || [],
    redirect: auth_45erroravC00AO3OcMeta?.redirect,
    component: () => import("/usr/src/app/pages/auth-error.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard",
    path: dashboardhKii8fXWAxMeta?.path ?? "/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: email_45not_45verifiedSQ2jAgjghLMeta?.name ?? "email-not-verified",
    path: email_45not_45verifiedSQ2jAgjghLMeta?.path ?? "/email-not-verified",
    meta: email_45not_45verifiedSQ2jAgjghLMeta || {},
    alias: email_45not_45verifiedSQ2jAgjghLMeta?.alias || [],
    redirect: email_45not_45verifiedSQ2jAgjghLMeta?.redirect,
    component: () => import("/usr/src/app/pages/email-not-verified.vue").then(m => m.default || m)
  },
  {
    name: email_45verificationz3JbXpSMV8Meta?.name ?? "email-verification",
    path: email_45verificationz3JbXpSMV8Meta?.path ?? "/email-verification",
    meta: email_45verificationz3JbXpSMV8Meta || {},
    alias: email_45verificationz3JbXpSMV8Meta?.alias || [],
    redirect: email_45verificationz3JbXpSMV8Meta?.redirect,
    component: () => import("/usr/src/app/pages/email-verification.vue").then(m => m.default || m)
  },
  {
    name: faqDkOohHQxeIMeta?.name ?? "faq",
    path: faqDkOohHQxeIMeta?.path ?? "/faq",
    meta: faqDkOohHQxeIMeta || {},
    alias: faqDkOohHQxeIMeta?.alias || [],
    redirect: faqDkOohHQxeIMeta?.redirect,
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordP0Nxfp4jaIMeta?.name ?? "forgot-password",
    path: forgot_45passwordP0Nxfp4jaIMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordP0Nxfp4jaIMeta || {},
    alias: forgot_45passwordP0Nxfp4jaIMeta?.alias || [],
    redirect: forgot_45passwordP0Nxfp4jaIMeta?.redirect,
    component: () => import("/usr/src/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: form_45validations3txF7MhGVMeta?.name ?? "form-validation",
    path: form_45validations3txF7MhGVMeta?.path ?? "/form-validation",
    meta: form_45validations3txF7MhGVMeta || {},
    alias: form_45validations3txF7MhGVMeta?.alias || [],
    redirect: form_45validations3txF7MhGVMeta?.redirect,
    component: () => import("/usr/src/app/pages/form-validation.vue").then(m => m.default || m)
  },
  {
    name: helpQYzekt5ZcHMeta?.name ?? "help",
    path: helpQYzekt5ZcHMeta?.path ?? "/help",
    meta: helpQYzekt5ZcHMeta || {},
    alias: helpQYzekt5ZcHMeta?.alias || [],
    redirect: helpQYzekt5ZcHMeta?.redirect,
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: password_45reset_45confirmed7ytzUXhCefMeta?.name ?? "password-reset-confirmed",
    path: password_45reset_45confirmed7ytzUXhCefMeta?.path ?? "/password-reset-confirmed",
    meta: password_45reset_45confirmed7ytzUXhCefMeta || {},
    alias: password_45reset_45confirmed7ytzUXhCefMeta?.alias || [],
    redirect: password_45reset_45confirmed7ytzUXhCefMeta?.redirect,
    component: () => import("/usr/src/app/pages/password-reset-confirmed.vue").then(m => m.default || m)
  },
  {
    name: policiessuN3sAamQPMeta?.name ?? "policies",
    path: policiessuN3sAamQPMeta?.path ?? "/policies",
    meta: policiessuN3sAamQPMeta || {},
    alias: policiessuN3sAamQPMeta?.alias || [],
    redirect: policiessuN3sAamQPMeta?.redirect,
    component: () => import("/usr/src/app/pages/policies.vue").then(m => m.default || m)
  },
  {
    name: referraluVAdb1aokEMeta?.name ?? "referral",
    path: referraluVAdb1aokEMeta?.path ?? "/referral",
    meta: referraluVAdb1aokEMeta || {},
    alias: referraluVAdb1aokEMeta?.alias || [],
    redirect: referraluVAdb1aokEMeta?.redirect,
    component: () => import("/usr/src/app/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: request_45password_45resetkac3zUDNL5Meta?.name ?? "request-password-reset",
    path: request_45password_45resetkac3zUDNL5Meta?.path ?? "/request-password-reset",
    meta: request_45password_45resetkac3zUDNL5Meta || {},
    alias: request_45password_45resetkac3zUDNL5Meta?.alias || [],
    redirect: request_45password_45resetkac3zUDNL5Meta?.redirect,
    component: () => import("/usr/src/app/pages/request-password-reset.vue").then(m => m.default || m)
  },
  {
    name: reset_45email_45sentycn9FTcbyyMeta?.name ?? "reset-email-sent",
    path: reset_45email_45sentycn9FTcbyyMeta?.path ?? "/reset-email-sent",
    meta: reset_45email_45sentycn9FTcbyyMeta || {},
    alias: reset_45email_45sentycn9FTcbyyMeta?.alias || [],
    redirect: reset_45email_45sentycn9FTcbyyMeta?.redirect,
    component: () => import("/usr/src/app/pages/reset-email-sent.vue").then(m => m.default || m)
  },
  {
    name: indexlGdpfxGbhNMeta?.name ?? "resources",
    path: indexlGdpfxGbhNMeta?.path ?? "/resources",
    meta: indexlGdpfxGbhNMeta || {},
    alias: indexlGdpfxGbhNMeta?.alias || [],
    redirect: indexlGdpfxGbhNMeta?.redirect,
    component: () => import("/usr/src/app/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: session_45timeout0guR2cT4qPMeta?.name ?? "session-timeout",
    path: session_45timeout0guR2cT4qPMeta?.path ?? "/session-timeout",
    meta: session_45timeout0guR2cT4qPMeta || {},
    alias: session_45timeout0guR2cT4qPMeta?.alias || [],
    redirect: session_45timeout0guR2cT4qPMeta?.redirect,
    component: () => import("/usr/src/app/pages/session-timeout.vue").then(m => m.default || m)
  },
  {
    name: sign_45in7sEcJLcQCLMeta?.name ?? "sign-in",
    path: sign_45in7sEcJLcQCLMeta?.path ?? "/sign-in",
    meta: sign_45in7sEcJLcQCLMeta || {},
    alias: sign_45in7sEcJLcQCLMeta?.alias || [],
    redirect: sign_45in7sEcJLcQCLMeta?.redirect,
    component: () => import("/usr/src/app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upmDla61cpoeMeta?.name ?? "sign-up",
    path: sign_45upmDla61cpoeMeta?.path ?? "/sign-up",
    meta: sign_45upmDla61cpoeMeta || {},
    alias: sign_45upmDla61cpoeMeta?.alias || [],
    redirect: sign_45upmDla61cpoeMeta?.redirect,
    component: () => import("/usr/src/app/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: tailwindVIAYjmNvpdMeta?.name ?? "tailwind",
    path: tailwindVIAYjmNvpdMeta?.path ?? "/tailwind",
    meta: tailwindVIAYjmNvpdMeta || {},
    alias: tailwindVIAYjmNvpdMeta?.alias || [],
    redirect: tailwindVIAYjmNvpdMeta?.redirect,
    component: () => import("/usr/src/app/pages/tailwind.vue").then(m => m.default || m)
  },
  {
    name: doctorsB27NwcXdoXMeta?.name ?? "user-doctors",
    path: doctorsB27NwcXdoXMeta?.path ?? "/user/doctors",
    meta: doctorsB27NwcXdoXMeta || {},
    alias: doctorsB27NwcXdoXMeta?.alias || [],
    redirect: doctorsB27NwcXdoXMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/doctors.vue").then(m => m.default || m)
  },
  {
    name: index9wmPuxyvMJMeta?.name ?? "user",
    path: index9wmPuxyvMJMeta?.path ?? "/user",
    meta: index9wmPuxyvMJMeta || {},
    alias: index9wmPuxyvMJMeta?.alias || [],
    redirect: index9wmPuxyvMJMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: manage_45consentuI9X6YPhtnMeta?.name ?? "user-manage-consent",
    path: manage_45consentuI9X6YPhtnMeta?.path ?? "/user/manage-consent",
    meta: manage_45consentuI9X6YPhtnMeta || {},
    alias: manage_45consentuI9X6YPhtnMeta?.alias || [],
    redirect: manage_45consentuI9X6YPhtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/manage-consent.vue").then(m => m.default || m)
  },
  {
    name: medications9HLT9bl1ZFMeta?.name ?? "user-medications",
    path: medications9HLT9bl1ZFMeta?.path ?? "/user/medications",
    meta: medications9HLT9bl1ZFMeta || {},
    alias: medications9HLT9bl1ZFMeta?.alias || [],
    redirect: medications9HLT9bl1ZFMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/medications.vue").then(m => m.default || m)
  },
  {
    name: pharmaciescvoyrwsH67Meta?.name ?? "user-pharmacies",
    path: pharmaciescvoyrwsH67Meta?.path ?? "/user/pharmacies",
    meta: pharmaciescvoyrwsH67Meta || {},
    alias: pharmaciescvoyrwsH67Meta?.alias || [],
    redirect: pharmaciescvoyrwsH67Meta?.redirect,
    component: () => import("/usr/src/app/pages/user/pharmacies.vue").then(m => m.default || m)
  }
]