import { datadogRum } from "@datadog/browser-rum";

export default defineNuxtPlugin((nuxtApp) => {
	datadogRum.init({
		applicationId: nuxtApp.$config.public.DATADOG_APPLICATION_ID,
		clientToken: nuxtApp.$config.public.DATADOG_CLIENT_TOKEN,
		site: "datadoghq.com",
		version: nuxtApp.$config.public.DD_VERSION,
		service: "benefits-portal",
		env: nuxtApp.$config.public.DD_ENV,
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
	});
	datadogRum.startSessionReplayRecording();
});
