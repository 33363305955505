import { createVuetify } from "vuetify";
import "vuetify/styles";
import * as components from "vuetify/components";
import { VProgressLinear } from "vuetify/components";
import * as directives from "vuetify/directives";
import { defineNuxtPlugin } from "nuxt/app";
// export default defineNuxtPlugin((nuxt: any) => {
// 	nuxt.vueApp.component("v-progress-linear", VProgressLinear);
// });
export default defineNuxtPlugin((nuxt: any) => {
	const vuetify = createVuetify({
		ssr: true,
		components,
		directives,
	});
	nuxt.vueApp.component("v-progress-linear", VProgressLinear);
	nuxt.vueApp.use(vuetify);
});
