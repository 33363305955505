export default defineNuxtRouteMiddleware((to) => {
	const authCookie = useCookie("cbp-token");
	const authWhitelist = [
		"/account-not-found",
		"/email-not-verified",
		"/email-verification",
		"/forgot-password",
		"/password-reset-confirmed",
		"/request-password-reset",
		"/reset-email-sent",
		"/session-timeout",
		"/sign-in",
		"/sign-up",
	];

	if (authCookie.value === undefined && !authWhitelist.includes(to.path)) {
		// Needs a different route when pages are created
		return navigateTo("/sign-in");
	}
});
